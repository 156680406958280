import React,{ useState } from "react";
import {NavLink} from "react-router-dom";

import PRATSLogo from "../Images/PRATS/PRATSLogo.webp";
import ComputerMainMenu_Icon from "../Images/Icons/ComputerMainMenu_Icon.svg";
import Media1 from "../Images/PRATS/PRATSAboutPhotos/media.webp";
import Media2 from "../Images/PRATS/PRATSAboutPhotos/media-2.webp";
import Media3 from "../Images/PRATS/PRATSAboutPhotos/media-3.webp";
import Media4 from "../Images/PRATS/PRATSAboutPhotos/media-4.webp";
import Media5 from "../Images/PRATS/PRATSAboutPhotos/media-5.webp";
import Media6 from "../Images/PRATS/PRATSAboutPhotos/media-6.webp";


import ComputerMainMenu from "../Forms/ComputerMainMenu";


import "../Styles/PRATSHome.css";
import "../Styles/PRATSAbout.css";

function PRATSAbout() {
  const [isComponentVisible, setIsComponentVisible] = useState(false);

  const toggleComponentVisibility = () => {
      setIsComponentVisible(!isComponentVisible);
  };
  return (
  <section>
    <section className="RightScroll">
            <div className= {`RightScrollMenu ${isComponentVisible ? 'expanded' : ''}`} onClick={toggleComponentVisibility}>
                {!isComponentVisible && (
                    <>
                    <p className="ComputerMainMenu_P"> Main </p>
                    <img
                        src={ComputerMainMenu_Icon} 
                        alt="Click this for the menu navigation bar" 
                        width="75px" 
                        height="75px" 
                        className={`ComputerModalButton ${isComponentVisible ? 'rotateAndMoveRight' : ''}`} />
                    </>
                )}
                    {isComponentVisible && 
                    <div>
                    <ComputerMainMenu/> 
                    </div>}
                    {!isComponentVisible && (
                    <>
                    <p className="ComputerMainMenu_P"> Menu </p>
                    </>
                )}
                </div>
    </section>
    <section className="PRATSHomePage">
      <div className="PRATSTopNavigation">
        <div className="PRATSTopLogo">
          <img src={PRATSLogo} alt="This is the PRATS Logo" width="300px" height="200px"/>
        </div>
        <div className="TopNavBarDiv">
        <NavLink to="/PRATSHome">
            <p className="PRATSDT"> Home </p>
        </NavLink>
        <NavLink to="/PRATSAbout">
            <p className="PRATSDT"> ACEs and Toxic Stress </p>
        </NavLink>
        <NavLink to="/PRATSServices">
            <p className="PRATSDT"> Services </p>
        </NavLink>
        <NavLink to="/PRATSTeam">
            <p className="PRATSDT"> Meet the Team! </p>
        </NavLink>
        <NavLink to="/PRATSContact">
            <p className="PRATSDT"> Contact </p>
        </NavLink>
        </div>
      </div>
    </section>
    <section className="PRATSHPHERO">
      <div className="PRATSHHero">
        <h1 className="PRATSH1About"> Adverse Childhood Experiences</h1>
        <div className="CRResources">
          <div className="CTRICRResource">
                        <div className="CRResourceImage">
                            <h2 className="PRATSD2Topic"> What are ACEs?</h2>
                            <img src={Media1} alt="." width="400px" height="400px"/>
                        </div>
                        <div className="CRTextBoxPRATS">
                          <p className="PRATSD2"> Adverse Childhood Experiences are traumatic events that occur in childhood between the ages of 1 to 17 years old. Some examples are experiencing violence, abuse, neglect, witnessing violence in the home or community, having a family member attempt or die by suicide, etc. However, it can also include aspects of the child's environment that can undermine their sense of safety, stability, and bonding. Some examples of this are the presence of substance use issues, mental health issues, and instability with their parents.</p>                     
                        </div>
          </div>
          <div className="CTRICRResource">
                        <div className="CRResourceImage">
                            <h2 className="PRATSD2Topic"> How may ACEs Impact <br></br> People & Community?</h2>
                            <img src={Media2} alt="." width="400px" height="400px" />
                        </div>
                        <div className="CRTextBoxPRATS">
                          <p className="PRATSD2"> ACEs have been linked to chronic health problems, mental illness, and substance use problems in adolescence and adulthood. It can also negatively impact education, job opportunities, and earning potential.  These effects together can increase the risk of injuries, sexually transmitted infections, maternal and child health problems (teen pregnancy, pregnancy complications, and fetal death),  and involvement in sex trafficking. Going back to the link to chronic health problems these problems can be the leading causes of death in cancer, diabetes, heart disease, and suicide.</p>                       
                          <p className="PRATSD2"> When it comes to focusing on the impact of ACEs solely on children it has been shown to affect brain development, immune systems, and stress response systems. All these changes impacts can affect the children's attention span, decision-making, and learning. As they grow into adults it can cause them difficulty in forming healthy and stable relationships and cause them to have unstable histories as well as create struggles between finances, jobs, and depression. These impacts can also be passed down to their own children. </p>
                        </div>
          </div>
          <div className="CTRICRResource">
                        <div className="CRResourceImage">
                            <h2 className="PRATSD2Topic"> Ways to combat ACEs?</h2>
                            <img src={Media3} alt="." width="400px" height="400px"/>
                        </div>
                        <div className="CRTextBoxPRATS">
                          <p className="PRATSD2"> Some ways to combat ACEs are to strengthen economic support to families by strengthening household financial security and by creating family-friendly work policies. Promoting social norms that protect against violence and adversity by creating public education campaigns, making legislative approaches to reduce corporal punishment, and bringing in men and boys to be allies in the prevention of violence. Teaching skills to people about social-emotional learning, how to have a safe dating life, and have to develop healthy skills to maintain a healthy relationship. Also connecting the youth to caring adults and activities such as mentoring programs, or after-school programs which the children can then turn to an adult and ask for their help. </p>                     
                        </div>
          </div>
          <h2 className="PRATSH1About"> Toxic Stress </h2>
          <div className="CTRICRResource">
            <div className="CRResourceImage">
                            <h2 className="PRATSD2Topic"> What is Toxic Stress?</h2>
                            <img src={Media4} alt="." width="400px" height="400px"/>
                        </div>
                        <div className="CRTextBoxPRATS">
                          <p className="PRATSD2"> Before going over toxic stress let's discuss what stress is in general. Stress is the response to demands that are encountered on a daily basis throughout one's lifetime. This can be related to both positive and negative experiences. Stressors are agents that produce stress and can be physical, emotional, environmental, and theoretical. The body has a response known as the fight of flight response to stress where physiologic changes occur with any encounter of stress or perceived stress.  </p>
                          <p className="PRATSD2"> The main difference with Toxic Stress is that it is a prolonged or permanent abnormal physiologic response to a stressor with a risk of damaging organs. The body also fails to fully recover from the stress. In children, it can be looked at as the lack of caregiver support, reassurance, or emotional attachments. This is because it prevents the stress response and the return of the body to a baseline function. Some examples of toxic stress are abuse, neglect, extreme poverty, violence, household dysfunction, and food scarcity. However, it has been found that children being exposed to less severe yet chronic daily stressors can also be toxic.  </p>                     
                        </div>
          </div>
          <div className="CTRICRResource">
                        <div className="CRResourceImage">
                            <h2 className="PRATSD2Topic"> How may Toxic Stress <br></br> Impact People & <br></br> Community?</h2>
                            <img src={Media5} alt="." width="400px" height="400px"/>
                        </div>
                        <div className="CRTextBoxPRATS">
                          <p className="PRATSD2"> In children, toxic stress can cause permanent changes to the brain structure, cause epigenetic alteration, and modify gene function. For long-term health, it can cause an increased risk for stress-related diseases. However, across the board, it can affect the neuroendocrine-immune system and stress response leads to prolonged and abnormal cortisol response. It also plays a role in depressive disorders, behavioral dysregulation, PTSD, and psychosis.</p>                     
                          <p className="PRATSD2"> For physical health, it can cause poorer health outcomes such as alcoholism, depression, cancer, obesity, and an increase in suicide attempts. Thus these added health risks can cause a bigger financial burden on the community to be able to continue to fund programs and hospitals.   </p>
                        </div>
          </div>
          <div className="CTRICRResource">
                        <div className="CRResourceImage">
                            <h2 className="PRATSD2Topic"> Ways to combat Toxic Stress?</h2>
                            <img src={Media6} alt="." width="400px" height="400px"/>
                        </div>
                        <div className="CRTextBoxPRATS">
                          <p className="PRATSD2"> Some ways to combat toxic stress are by considering preventive measures, having a routine, and looking into treatment.</p>                     
                          <p className="PRATSD2"> Firstly, preventive measures promoting positive environmental influences and interactions with supportive caretakers can create a healthy environment where one does not have additional stress factors.</p>
                          <p className="PRATSD2"> Secondly, with routine one can have anticipatory guidance. This can be encouraging positive parenting, identifying strengths to support families, how to build resilience, and help develop buffers required to handle stress.</p>
                          <p className="PRATSD2"> Finally, treatment requires timely intervention, goals that are to decrease stressors, and an individual response to the stressor. Now there are two forms of treatment one that concerns the individual and family and one that concerns the community. For individuals and families, they focus on helping children learn how to shut off their stress response in a healthy manner and forming interventions focused on caretakers. These interventions comprised parenting classes, home visits to improve parenting, telephone support, family-based programs, and access to social resources for parents. For community treatment, it focuses on strengthening neighborhood-level resources and having effective and long-term programs that benefit children.  </p>
                        </div>
          </div>
        </div>
      </div>
    </section>
  </section>
  );
}

export default PRATSAbout;