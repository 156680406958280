import React,{ useState } from "react";
import {NavLink} from "react-router-dom";

import PRATSLogo from "../Images/PRATS/PRATSLogo.webp";
import ComputerMainMenu_Icon from "../Images/Icons/ComputerMainMenu_Icon.svg";
import Media7 from "../Images/PRATS/PRATSAboutPhotos/Media7.webp";
import Media8 from "../Images/PRATS/PRATSAboutPhotos/Media8.webp";
import Media9 from "../Images/PRATS/PRATSAboutPhotos/Media9.webp";


import ComputerMainMenu from "../Forms/ComputerMainMenu";


import "../Styles/PRATSHome.css";
import "../Styles/PRATSAbout.css";

function PRATSServices() {
  const [isComponentVisible, setIsComponentVisible] = useState(false);

  const toggleComponentVisibility = () => {
      setIsComponentVisible(!isComponentVisible);
  };
  return (
  <section>
    <section className="RightScroll">
            <div className= {`RightScrollMenu ${isComponentVisible ? 'expanded' : ''}`} onClick={toggleComponentVisibility}>
                {!isComponentVisible && (
                    <>
                    <p className="ComputerMainMenu_P"> Main </p>
                    <img
                        src={ComputerMainMenu_Icon} 
                        alt="Click this for the menu navigation bar" 
                        width="75px" 
                        height="75px" 
                        className={`ComputerModalButton ${isComponentVisible ? 'rotateAndMoveRight' : ''}`} />
                    </>
                )}
                    {isComponentVisible && 
                    <div>
                    <ComputerMainMenu/> 
                    </div>}
                    {!isComponentVisible && (
                    <>
                    <p className="ComputerMainMenu_P"> Menu </p>
                    </>
                )}
                </div>
      </section>
    <section className="PRATSHomePage">
      <div className="PRATSTopNavigation">
        <div className="PRATSTopLogo">
          <img src={PRATSLogo} alt="This is the PRATS Logo" width="300px" height="200px"/>
        </div>
        <div className="TopNavBarDiv">
        <NavLink to="/PRATSHome">
            <p className="PRATSDT"> Home </p>
        </NavLink>
        <NavLink to="/PRATSAbout">
            <p className="PRATSDT"> ACEs and Toxic Stress </p>
        </NavLink>
        <NavLink to="/PRATSServices">
            <p className="PRATSDT"> Services </p>
        </NavLink>
        <NavLink to="/PRATSTeam">
            <p className="PRATSDT"> Meet the Team! </p>
        </NavLink>
        <NavLink to="/PRATSContact">
            <p className="PRATSDT"> Contact </p>
        </NavLink>
        </div>
      </div>
    </section>
    <section className="PRATSHPHERO">
      <div className="PRATSHHero">
        <h1 className="PRATSH1About"> Services </h1>
      </div>
      <div className="PRATSHHEROTwo">
        <div className="ShareSpaceServices">
          <p className="PRATSD2"> All Services provided are free and will not be charged to insurance nor does one need insurance to access them </p>
          <h2 className="PRATSD2Topic"> Counseling </h2>
          <p className="PRATSD2"> <strong> Provided by Master Level Social Workers </strong></p>
          <p className="PRATSD2"> Those who take the counseling services will be working with social workers who have at least a masters. That means that the social workers have the training necessary to be assisting with more severe cases</p>
          <p className="PRATSD2"> <strong>Short-Term Treatment </strong></p>
          <p className="PRATSD2"> You will be meeting for 8 to 12 sessions before your services are brought to an end </p>
          <p className="PRATSD2"> <strong> Focuses on Tackling </strong></p>
          <p className="PRATSD2"> This services focuses on those who are dealing with mild to moderate mental health needs</p>
          <p className="PRATSD2"> <strong>Aims to Assist With </strong></p>
          <p className="PRATSD2"> In tackling mental health needs the social workers want to help lessen one's anxiety about life, and depression. In doing so they hope to set goals to help the client to handle their mental health needs. </p>
          <p className="PRATSD2"> <strong>Location? </strong></p>
          <p className="PRATSD2"> Zoom for online & In-Person can be offered.</p>  
          <h2 className="PRATSD2Topic"> Mentoring </h2>
          <p className="PRATSD2"> <strong> Provided by Volunteers </strong></p>
          <p className="PRATSD2"> The mentoring services are provided by volunteers who are interested in a social work or psychology pathway. The volunteers join on in hopes of gaining experiences and skills to better prepare them.  </p>
          <p className="PRATSD2"> <strong> Sessions are Long-Term </strong> </p>
          <p className="PRATSD2"> Unlike the counseling services the mentor services continue until the mentee decides to end services. </p>
          <p className="PRATSD2"> <strong>Focuses on Tackling </strong></p>
          <p className="PRATSD2"> Mentors aim to help lessen the stress that can happen during transitional periods in life. This can be from going to work to school, high school to college, or even switching careers. The mentors will also help tackle some general anxiety about life and help with goal setting for future positions the mentee may want. </p>
          <p className="PRATSD2"> <strong> Location? </strong></p>
          <p className="PRATSD2"> Zoom for online & In-Person can be offered.</p>
        </div>
        <div className="CRResources">
          <h2 className="PRATSH1About"> Benefits of These Services </h2>
          <div className="CTRICRResource">
                        <div className="CRResourceImage">
                            <h2 className="PRATSD2Topic"> Getting to Know <br></br> Oneself Better</h2>
                            <img src={Media7} alt="." width="400px" height="400px"/>
                        </div>
                        <div className="CRTextBoxPRATS">
                          <p className="PRATSD2"> Being able to have a safe space to evaluate what is important to you, how you currently are mentally, and what is your next step in life can help you understand yourself. Understanding one's goal can also help one better plan their time and what activites they give their own energy to.</p>                     
                        </div>
          </div>
          <div className="CTRICRResource">
                        <div className="CRResourceImage">
                            <h2 className="PRATSD2Topic"> Having Support <br></br>for Challenging Times</h2>
                            <img src={Media8} alt="." width="400px" height="400px" />
                        </div>
                        <div className="CRTextBoxPRATS">
                          <p className="PRATSD2"> Often times people go through struggles or transitional periods alone. This can make them feel isolated and as if they are fighting against the world. However, in doing these services you can have someone who is guiding you through your next step or through the struggle you are facing so you have someone on your side. </p>                       
                        </div>
          </div>
          <div className="CTRICRResource">
                        <div className="CRResourceImage">
                            <h2 className="PRATSD2Topic"> Learning New Resources</h2>
                            <img src={Media9} alt="." width="400px" height="400px"/>
                        </div>
                        <div className="CRTextBoxPRATS">
                          <p className="PRATSD2"> For those who do know what they want to do or know that they want to learn more of something these services can get them connected to people. Via the mentors, they can meet someone who can help them with understanding how to approach a master's program, how to join a credit union, or what community resources they can get if they need or want it. </p>                     
                        </div>
          </div>
        </div>
      </div>
    </section>
  </section>
  );
}

export default PRATSServices;