import React,{ useState } from "react";
import {NavLink} from "react-router-dom";
import "../Styles/Home.css";

import HeroImage from "../Images/Comprehensive-Services-Icon.webp";
import ProblemImage from "../Images/Medical-Billing-Example.png";
import AndyJohnson from "../Images/CTRI CEO Image.jpeg";
import CTRILogo from "../Images/CTRI_Logo.png";
import Menu_Icon from "../Images/Icons/Menu_Icon.svg";
import ComputerMainMenu_Icon from "../Images/Icons/ComputerMainMenu_Icon.svg";

import EnrollmentForm from "../Forms/enrollmentForm";
import ComputerMainMenu from "../Forms/ComputerMainMenu";

function Home () {
    const [isComponentVisible, setIsComponentVisible] = useState(false);

    const toggleComponentVisibility = () => {
        setIsComponentVisible(!isComponentVisible);
    };
    return (
        <section>
            <section className="MobileLogo">
                <div className="LogoDown">
                    <NavLink to="/">
                        <img src={CTRILogo} alt="Community Translational Research Institute's main logo" width="300px" height="100px" />
                    </NavLink>
                </div>
                <div className="MobileNavBar" onClick={toggleComponentVisibility}>
                    <img
                        src={Menu_Icon} 
                        alt="Click this for the menu navigation bar" 
                        width="75px" 
                        height="75px" 
                        className={`ModalButton ${isComponentVisible ? 'rotate' : ''}`} />
                    <p className="NavBar_P"> Menu </p>
                </div>
                {isComponentVisible && 
                <div> <EnrollmentForm/> </div>}
            </section>
            <section className="RightScroll">
            <div className= {`RightScrollMenu ${isComponentVisible ? 'expanded' : ''}`} onClick={toggleComponentVisibility}>
                {!isComponentVisible && (
                    <>
                    <p className="ComputerMainMenu_P"> Main </p>
                    <img
                        src={ComputerMainMenu_Icon} 
                        alt="Click this for the menu navigation bar" 
                        width="75px" 
                        height="75px" 
                        className={`ComputerModalButton ${isComponentVisible ? 'rotateAndMoveRight' : ''}`} />
                    </>
                )}
                    {isComponentVisible && 
                    <div>
                    <ComputerMainMenu/> 
                    </div>}
                    {!isComponentVisible && (
                    <>
                    <p className="ComputerMainMenu_P"> Menu </p>
                    </>
                )}
                </div>
            </section>
            <section className="HeroSection">
                <div>
                    <h1> We Place Holistic Care<br/> Into SoCal Communities</h1>
                    <h2> Our Services Empower You To Live your Best Life.</h2>
                    <p className="CTANudge"> Need a nudge?</p>
                    <NavLink to="/About">
                        <p className="MainCTA"> Click To Learn Our Story </p>
                    </NavLink>
                </div>
                <div className="HeroImgMobile">
                    <img src={HeroImage} alt="We help provide mental health, health insurance, education, medical screening, work, and health education services to you and your community." width="500px" height="500px"/>
                </div>
            </section>
            <section className="ProblemSection">
                <div className="ProblemContainer">
                    <h2 className="h2Label"> Expensive Medical Bills <br /> Ruin Your Family's Life </h2>
                    <p className="pLabel"> Costly hospital expenses destroy family wealth. Often leaving loved ones to start over on their financial journey.</p>
                    <NavLink to="/About" onClick={topClick}>
                        <p className="GhostCTA"> See Our Process </p>
                    </NavLink>
                </div>
                <div className="HeroImgMobile">
                    <img src={ProblemImage} alt="The Community Translational Research Institute helps you protect your family's wealth by protecting your health both physical and mental." width="240px" height="500px"/>
                </div>
            </section>
            <section className="FunFact">
                <div>
                    <p className="CTANudge"> Fun Fact: CTRI has been helping communities avoid medical bill debt since 2014.</p>
                </div>
            </section>
            <section className="SolutionSection">
                <div>
                    <h2 className="h2Label">Your Path To Health In 4 Steps</h2>
                    <p className="pLabel"> Step 1: Create Your Account</p>
                    <p className="pLabel"> Step 2: Wait To Be Contacted</p>
                    <p className="pLabel"> Step 3: Accept Our Help</p>
                    <p className="pLabel"> Step 4: Enjoy Your Health</p>
                    <NavLink to="https://docs.google.com/forms/d/e/1FAIpQLScsNOOqpXkN-6BCxjqEWas6Yt1fn0gymD1P9VKKY70leKhzaQ/viewform" target="_blank">
                        <p className="MainCTA"> Start Your Journey, Today </p>
                    </NavLink>
                </div>
            </section>
            <section className="FunFact">
                <p className="CTANudge"> Quick Fact: CTRI Works With Universities To Reduce Costs Associated With Holistic Care. </p>
                <h2 className="h2Label"> What Is The Community Translational Research Institute?</h2>
            </section>
            <section className="ShowFaceSection">
                <div className="ShowFaceContainer">
                    <p className="pLabel"> "The Community Translational Research Institute is a network of researchers
                        and organizations dedicated too uplifting communities in need. We acknowledge
                        that healthcare is difficult and confusing, but our mission is to make it simple
                        and accessible for you and your family."
                    </p>
                    <p className="CTANudge"> Still on the fence?</p>
                    <NavLink to="/About" onClick={topClick}>
                        <p className="MainCTA"> Learn More </p>
                    </NavLink>
                </div>
                <div className="ShowFaceDiv">
                    <div className="FaceWrap">
                    <img src={AndyJohnson} alt="Doctor Andy Johnson is a tenured health scientist and currently holds the position as CTRI's Chief Executive Officer."/>
                    </div>
                    <div className="ShowFaceContainer2">
                    <p><b> - Dr. Johnson, Andy </b></p>
                    <p className="CTANudge"> CEO of CTRI</p>
                    </div>
                </div>
            </section>
            <section className="FunFact">
                <p className="CTANudge"> Did You Know? CTRI Is Operated By Public Health Professionals.</p>
            </section>
            <section className="QuoteSection">
                <div>
                <h2 className="h2Quote"> "Health is not created alone. Health is created <br></br>
                together with people who support you."</h2>
                <p className="JohnsonName"><b> - Dr. Johnson, Andy </b></p>
                <p className="CTANudge"> CEO of CTRI</p>
                </div>
            </section>
            <section className="FinalCTASection">
                <div className="CTABox">
                    <h2 className="h2LabelCTA"> Want To Grow Into Healthier Life?</h2>
                    <p className="plabelDescriptor">Do not let your health stop you from enjoying life.</p>
                    <p className="CTANudge">Want Support?</p>
                    <NavLink to="/LogIn" onClick={topClick}>
                        <p className="MainCTA"> Join Our community, Today </p>
                    </NavLink>
                </div>
                <div className="CTABox1">
                <h2 className="h2LabelCTA"> Are You Looking For An Internship?</h2>
                    <p className="plabelDescriptor">We provide you a tailored experience to meet your requirements.</p>
                    <p className="CTANudge">Want More?</p>
                    <NavLink to="/Internships" onClick={topClick}>
                        <p className="MainCTA"> Click For Information </p>
                    </NavLink>
                </div>
                <div className="CTABox1">
                    <h2 className="h2LabelCTA"> Want To Join Our Network?</h2>
                    <p className="plabelDescriptor">We help facilitate your organization through subsidized services.</p>
                    <p className="CTANudge">Skeptical?</p>
                    <NavLink to="/About" onClick={topClick}>
                        <p className="MainCTA"> Learn How We Help </p>
                    </NavLink>

                </div>
            </section>
        </section>
    )
};
function topClick(e) {
    window.scrollTo(0,0);
}
export default Home;