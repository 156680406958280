import React, {useState} from "react";
import {NavLink} from "react-router-dom";
import "../Styles/Internships.css";

import Menu_Icon from "../Images/Icons/Menu_Icon.svg";
import CTRILogo from "../Images/CTRI_Logo.png";
import ComputerMainMenu_Icon from "../Images/Icons/ComputerMainMenu_Icon.svg";


import EnrollmentForm from "../Forms/enrollmentForm";
import ComputerMainMenu from "../Forms/ComputerMainMenu";


function Internships () {
    const [isComponentVisible, setIsComponentVisible] = useState(false);

    const toggleComponentVisibility = () => {
        setIsComponentVisible(!isComponentVisible);
    };
    return (
        <section>
            <section className="MobileLogo">
                <div className="LogoDown">
                    <NavLink to="/">
                        <img src={CTRILogo} alt="Community Translational Research Institute's main logo" width="300px" height="100px" />
                    </NavLink>
                </div>
                <div className="MobileNavBar" onClick={toggleComponentVisibility}>
                <img
                    src={Menu_Icon} 
                    alt="Click this for the menu navigation bar" 
                    width="75px" 
                    height="75px" 
                    className={`ModalButton ${isComponentVisible ? 'rotate' : ''}`} />                    <p className="NavBar_P"> Menu </p>
                </div>
                {isComponentVisible && 
                <div> <EnrollmentForm/> </div>}
            </section>
            <section className="RightScroll">
            <div className= {`RightScrollMenu ${isComponentVisible ? 'expanded' : ''}`} onClick={toggleComponentVisibility}>
                {!isComponentVisible && (
                    <>
                    <p className="ComputerMainMenu_P"> Main </p>
                    <img
                        src={ComputerMainMenu_Icon} 
                        alt="Click this for the menu navigation bar" 
                        width="75px" 
                        height="75px" 
                        className={`ComputerModalButton ${isComponentVisible ? 'rotateAndMoveRight' : ''}`} />
                    </>
                )}
                    {isComponentVisible && 
                    <div>
                    <ComputerMainMenu/> 
                    </div>}
                    {!isComponentVisible && (
                    <>
                    <p className="ComputerMainMenu_P"> Menu </p>
                    </>
                )}
                </div>
            </section>
            <section className="InternshipHero">
                <div className="IH_Div">
                <h1 className="IH_Div_One"> Kickstart Your Healthcare Career <br></br>With A Student Internship </h1>
                <h2 className="IH_Div_Two"> We Work With Your University To Ensure Academic Credit.</h2>
                </div>
                <div className="Buttons">
                <NavLink to="https://docs.google.com/forms/d/e/1FAIpQLScsNOOqpXkN-6BCxjqEWas6Yt1fn0gymD1P9VKKY70leKhzaQ/viewform" target="_blank">
                        <p className="MainCTA"> Student Application</p>
                </NavLink>
                <NavLink to="https://docs.google.com/forms/d/e/1FAIpQLScsNOOqpXkN-6BCxjqEWas6Yt1fn0gymD1P9VKKY70leKhzaQ/viewform" target="_blank">
                        <p className="GhostCTA"> Non-Student Application</p>
                </NavLink>
                </div>
            </section>
            <section className="InternshipMVP">
                <div>
                <h2 className="SectionH1"> Do Not Let Paperwork Stop You </h2>
                <p className="SectionH2"> - We work with your university for academic credit.</p>
                <p className="SectionH2"> - We help you develop a project deliverable.</p>
                <p className="SectionH2"> - We expose you to real-world experience.</p>
                </div>
                <div className="Buttons">
                    <NavLink to="https://docs.google.com/forms/d/e/1FAIpQLScsNOOqpXkN-6BCxjqEWas6Yt1fn0gymD1P9VKKY70leKhzaQ/viewform" target="_blank">
                        <p className="GhostCTA"> Apply Here, Today</p>
                    </NavLink>
                </div>
            </section>
            <section className="InternshipCTA">
                <h2 className="CTAH1"> Our Paperwork Is Made Easy For You:</h2>
                <div className="SubSection">
                    <div className="CTANumberBox">
                        <p className="CTANumber"> 1 </p>
                    </div>
                    <div className="InternshipCTABox">
                        <h3 className="CTAH2"> Apply and Interview </h3>
                        <p className="CTAH3"> - After receiving your application, we <br></br> review and schedule a meeting.</p>
                        <div className="GhostCTA_LastBox">
                            <NavLink to="https://docs.google.com/forms/d/e/1FAIpQLScsNOOqpXkN-6BCxjqEWas6Yt1fn0gymD1P9VKKY70leKhzaQ/viewform" target="_blank">
                                <p className="GhostCTA"> Internship Application</p>
                            </NavLink>
                        </div>
                    </div>
                </div>
                <div className="SubSection">
                    <div className="InternshipCTABox">
                        <h3 className="CTAH2"> Get Matched</h3>
                        <p className="CTAH3"> - We do our best to match you with <br></br> professionals in your chosen field.</p>
                        <div className="GhostCTA_LastBox">
                            <NavLink to="https://docs.google.com/forms/d/e/1FAIpQLScsNOOqpXkN-6BCxjqEWas6Yt1fn0gymD1P9VKKY70leKhzaQ/viewform" target="_blank">
                                <p className="GhostCTA"> Start The Process, Today</p>
                            </NavLink>
                        </div>
                    </div>
                    <div>
                        <p className="CTANumber"> 2 </p>
                    </div>
                </div>
                <div className="SubSection">
                    <div>
                        <p className="CTANumber"> 3 </p>
                    </div>
                    <div className="InternshipCTABox">
                        <h3 className="CTAH2"> Post-Internship Success</h3>
                        <p className="CTAH3"> - Leverage your experiences in graduate applications.</p>
                        <p className="CTAH3"> - Flex your new resume for a job in healthcare.</p>
                        <div className="GhostCTA_LastBox">
                            <NavLink to="https://docs.google.com/forms/d/e/1FAIpQLScsNOOqpXkN-6BCxjqEWas6Yt1fn0gymD1P9VKKY70leKhzaQ/viewform" target="_blank">
                                <p className="GhostCTA"> Start Interning, Now</p>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </section>
        </section>
    )
};

export default Internships;