import React, {useState} from "react";
import {NavLink} from "react-router-dom";
import "../Styles/About.css";

import CTRILogo from "../Images/CTRI_Logo.png";
import Menu_Icon from "../Images/Icons/Menu_Icon.svg";
import ComputerMainMenu_Icon from "../Images/Icons/ComputerMainMenu_Icon.svg";

import EnrollmentForm from "../Forms/enrollmentForm";
import ComputerMainMenu from "../Forms/ComputerMainMenu";

function About () {

    const [isComponentVisible, setIsComponentVisible] = useState(false);

    const toggleComponentVisibility = () => {
        setIsComponentVisible(!isComponentVisible);
    };

    return (
        <section>
            <section className="MobileLogo">
                <div className="LogoDown">
                    <NavLink to="/">
                        <img src={CTRILogo} alt="Community Translational Research Institute's main logo" width="300px" height="100px" />
                    </NavLink>
                </div>
                <div className="MobileNavBar" onClick={toggleComponentVisibility}>
                    <img
                        src={Menu_Icon} 
                        alt="Click this for the menu navigation bar" 
                        width="75px" 
                        height="75px" 
                        className={`ModalButton ${isComponentVisible ? 'rotate' : ''}`} />
                        <p className="NavBar_P"> Menu </p>
                </div>
                {isComponentVisible && 
                <div> <EnrollmentForm/> </div>}
            </section>
            <section className="RightScroll">
            <div className= {`RightScrollMenu ${isComponentVisible ? 'expanded' : ''}`} onClick={toggleComponentVisibility}>
                {!isComponentVisible && (
                    <>
                    <p className="ComputerMainMenu_P"> Main </p>
                    <img
                        src={ComputerMainMenu_Icon} 
                        alt="Click this for the menu navigation bar" 
                        width="75px" 
                        height="75px" 
                        className={`ComputerModalButton ${isComponentVisible ? 'rotateAndMoveRight' : ''}`} />
                    </>
                )}
                    {isComponentVisible && 
                    <div>
                    <ComputerMainMenu/> 
                    </div>}
                    {!isComponentVisible && (
                    <>
                    <p className="ComputerMainMenu_P"> Menu </p>
                    </>
                )}
                </div>
            </section>
            <section className="AboutHero">
                <div className="AHeroContainer1">
                    <h1 className="h1HERO"> The Community Translational Research Institute </h1>
                    <h2 className="h2HERO"> We Provide Comprehensive Medical Services To Empower You Into A Culture of Health.</h2>
                </div>
                <div className="AHeroContainer2">
                <p className="CTANudge"> Are You A Community Partner?</p>
                <NavLink to="https://docs.google.com/forms/d/e/1FAIpQLSdBIRDFmRYM34WOkh-lTsSiy2jJzztpp_o_nGNJRjJF107PEw/viewform" target="_blank">
                        <p className="MainCTA"> Join Our Network </p>
                </NavLink>
                </div>
            </section>
            <section className="AboutMVP">
                <div className="MVPContainer">
                    <h2 className="h2Label"> Why Should You Let Us Help You? </h2>
                    <p className="pMVP"> Our mission to deliver high-quality healthcare at an affordable price is the reason we exist. After analyzing years of medical data, we decided to use our training, experience, and education to improve the world.</p>
                </div>
                <div className="MVPContainer">
                    <h2 className="h2Label"> Envision A Brighter Future</h2>
                    <p className="pMVP">We see a healthier future as a reality by bringing people together to solve complex issues. By accepting our help, you become an advocate for a healthier future. </p>
                </div>
                <div className="MVPContainer">
                    <h2 className="h2Label"> Become A Health Advocate</h2>
                    <p className="pMVP"> Health advocates are people that see those around them as their family. A community of families should be supportive in their physical and mental health journeys.</p>
                </div>
            </section>
            <section className="AboutCTA">
                <div className="CTABox">
                    <h2 className="CTABoxh2"> Gain Healthcare Experience</h2>
                    <p className="CTADescriptor"> Intern for Academic Credit or Professional Experience.</p>
                    <div className="CTABoxCTA">
                        <p className="CTANudge"> Click to Start</p>
                        <NavLink to="https://docs.google.com/forms/d/e/1FAIpQLScsNOOqpXkN-6BCxjqEWas6Yt1fn0gymD1P9VKKY70leKhzaQ/viewform" target="_blank">
                            <p className="MainCTA"> Internship Application </p>
                        </NavLink>
                    </div>
                </div>
                <div className="CTABox1">
                    <h2 className="CTABoxh2"> Expand Your Organization's Reach</h2>
                    <p className="CTADescriptor"> Partner With Us To Build A Stronger Healthier Community.</p>
                    <div className="CTABoxCTA">
                        <p className="CTANudge"> Click To Join</p>
                        <NavLink to="https://docs.google.com/forms/d/e/1FAIpQLSdBIRDFmRYM34WOkh-lTsSiy2jJzztpp_o_nGNJRjJF107PEw/viewform" target="_blank">
                            <p className="MainCTA"> Work With Us </p>
                        </NavLink>
                    </div>
                </div>
                <div className="CTABox1">
                    <h2 className="CTABoxh2"> Donate to A Worthy Cause</h2>
                    <p className="CTADescriptor"> Support Our Mission To Build A Healthier Southern California.</p>
                    <div className="CTABoxCTA">
                        <p className="CTANudge"> See Our Impact</p>
                        <NavLink to="https://docs.google.com/forms/d/e/1FAIpQLSebyZm8qdZbzSn9aX9_Q0K-O7w6y2ROxOawUrHlTsGoTdvA9w/viewform" target="_blank">
                             <p className="MainCTA"> Support Our Cause </p>
                        </NavLink>
                    </div>
                </div>
            </section>
            <section className="AboutInternshipFAQs">
                <div className="MVPContainer">
                    <h2 className="h2toh1"> Internship Frequently Asked Questions </h2>
                    <p className="h2Descriptor"> We support people in their goals of entering the field of healthcare.</p>
                    <p className="h2Descriptor"> We believe organizations should create opportunities for people to emerge as leaders.</p>
                </div>
                <div className="MVPContainer">
                    <h3 className="h3Descriptor"> Do I Obtain Clinical Experience?</h3>
                    <p className="h2Descriptor"> Clinical experience depends on availability as we partner with health systems and universities to deliver care.</p>
                </div>
                <div className="MVPContainer">
                    <h3 className="h3Descriptor"> Is The Experience Tailored To Me?</h3>
                    <p className="h2Descriptor"> Our internship experiences focus on professional development, so it aligns with your future career endeavors.</p>
                </div>
                <div className="MVPContainer">
                    <h3 className="h3Descriptor"> Will You Work With My University?</h3>
                    <p className="h2Descriptor"> Yes, our goal is to support your professional and academic development.</p>
                </div>
                <div className="MainCTA_EndDiv">
                    <p className="CTANudge_End"> Are You A Community Partner?</p>
                    <NavLink to="https://docs.google.com/forms/d/e/1FAIpQLSdBIRDFmRYM34WOkh-lTsSiy2jJzztpp_o_nGNJRjJF107PEw/viewform" target="_blank">
                        <p className="MainCTA_End"> Click to Collaborate </p>
                    </NavLink>
                </div>
            </section>
        </section>
    )
};
export default About;