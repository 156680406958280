import React,{ useState } from "react";
import {NavLink} from "react-router-dom";

import PRATSLogo from "../Images/PRATS/PRATSLogo.webp";
import ComputerMainMenu_Icon from "../Images/Icons/ComputerMainMenu_Icon.svg";


import ComputerMainMenu from "../Forms/ComputerMainMenu";


import "../Styles/PRATSHome.css";
import "../Styles/PRATSAbout.css";

function PRATSContact() {
  const [isComponentVisible, setIsComponentVisible] = useState(false);

  const toggleComponentVisibility = () => {
      setIsComponentVisible(!isComponentVisible);
  };
  return (
  <section>
    <section className="RightScroll">
            <div className= {`RightScrollMenu ${isComponentVisible ? 'expanded' : ''}`} onClick={toggleComponentVisibility}>
                {!isComponentVisible && (
                    <>
                    <p className="ComputerMainMenu_P"> Main </p>
                    <img
                        src={ComputerMainMenu_Icon} 
                        alt="Click this for the menu navigation bar" 
                        width="75px" 
                        height="75px" 
                        className={`ComputerModalButton ${isComponentVisible ? 'rotateAndMoveRight' : ''}`} />
                    </>
                )}
                    {isComponentVisible && 
                    <div>
                    <ComputerMainMenu/> 
                    </div>}
                    {!isComponentVisible && (
                    <>
                    <p className="ComputerMainMenu_P"> Menu </p>
                    </>
                )}
                </div>
      </section>
    <section className="PRATSHomePage">
      <div className="PRATSTopNavigation">
        <div className="PRATSTopLogo">
          <img src={PRATSLogo} alt="This is the PRATS Logo" width="300px" height="200px"/>
        </div>
        <div className="TopNavBarDiv">
        <NavLink to="/PRATSHome">
            <p className="PRATSDT"> Home </p>
        </NavLink>
        <NavLink to="/PRATSAbout">
            <p className="PRATSDT"> ACEs and Toxic Stress </p>
        </NavLink>
        <NavLink to="/PRATSServices">
            <p className="PRATSDT"> Services </p>
        </NavLink>
        <NavLink to="/PRATSTeam">
            <p className="PRATSDT"> Meet the Team! </p>
        </NavLink>
        <NavLink to="/PRATSContact">
            <p className="PRATSDT"> Contact </p>
        </NavLink>
        </div>
      </div>
    </section>
    <section className="PRATSHPHERO">
      <div className="PRATSHHero">
        <h1 className="PRATSH1About"> Contact Us </h1>
      </div>
      <div className="PRATSHHEROTwo">
        <div className="ShareSpaceServicesContact">
          <h2 className="PRATSH1AboutContact"> If you want to partake in our services, learn more, or <br></br> even join the team please reach out to us via Email or <br></br> Phone Call </h2>
          <p className="PRATSD2"> <strong> Email: </strong></p>
          <p className="PRATSD2"> wesupporthealth@ctris.org</p>
          <p className="PRATSD2"> <strong>Direct Questions to Valerie Smith at: </strong></p>
          <p className="PRATSD2"> 909-255-9415 </p>
        </div>
      </div>
    </section>
  </section>
  );
}

export default PRATSContact;