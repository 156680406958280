import React from "react";
import {NavLink} from "react-router-dom";

import "../Styles/EnrollmentForm.css";

import CTRILogo from "../Images/CTRI_Logo.png";

function EnrollmentForm() {
  return (
    <section className="MobileNavForm">
      <NavLink to="/" className="MobileLogoImage">
        <img src={CTRILogo} alt="Community Translational Research Institute's main logo" width="300px" height="100px" />
      </NavLink>
      <p className="BottomMobileNav"> Thank you for visiting our page</p>
      <div className="MobileLinkNav">
            <NavLink to="/" activeClassName="active"> Home</NavLink>
            <NavLink to="/About" activeClassName="active"> About</NavLink>
            <NavLink to="/CommunityResources" activeClassName="active"> Community Resources</NavLink>
            <NavLink to="/LogIn" activeClassName="active"> Log-in </NavLink>
      </div>
      <p className="BottomMobileNav">*** See bottom page for more options</p>
    </section>
  );
};
export default EnrollmentForm;
