import React, {useState} from "react";
import {NavLink} from "react-router-dom";
import "../Styles/Healthblog.css";

import CTRILogo from "../Images/CTRI_Logo.png";
import Menu_Icon from "../Images/Icons/Menu_Icon.svg";
import InternshipSuccess from "../Images/InternshipSucess.webp";
import CTRIAwards from "../Images/CTRIAwards.webp";
import ComputerMainMenu_Icon from "../Images/Icons/ComputerMainMenu_Icon.svg";


import EnrollmentForm from "../Forms/enrollmentForm";
import ComputerMainMenu from "../Forms/ComputerMainMenu";


function Blog () {
    const [isComponentVisible, setIsComponentVisible] = useState(false);

    const toggleComponentVisibility = () => {
        setIsComponentVisible(!isComponentVisible);
    };

    return (
        <section>
            <section className="MobileLogo">
                <div className="LogoDown">
                    <NavLink to="/">
                        <img src={CTRILogo} alt="Community Translational Research Institute's main logo" width="300px" height="100px" />
                    </NavLink>
                </div>
                <div className="MobileNavBar" onClick={toggleComponentVisibility}>
                    <img
                        src={Menu_Icon} 
                        alt="Click this for the menu navigation bar" 
                        width="75px" 
                        height="75px" 
                        className={`ModalButton ${isComponentVisible ? 'rotate' : ''}`} />
                        <p className="NavBar_P"> Menu </p>
                </div>
                {isComponentVisible && 
                <div> <EnrollmentForm/> </div>}
            </section>
            <section className="RightScroll">
            <div className= {`RightScrollMenu ${isComponentVisible ? 'expanded' : ''}`} onClick={toggleComponentVisibility}>
                {!isComponentVisible && (
                    <>
                    <p className="ComputerMainMenu_P"> Main </p>
                    <img
                        src={ComputerMainMenu_Icon} 
                        alt="Click this for the menu navigation bar" 
                        width="75px" 
                        height="75px" 
                        className={`ComputerModalButton ${isComponentVisible ? 'rotateAndMoveRight' : ''}`} />
                    </>
                )}
                    {isComponentVisible && 
                    <div>
                    <ComputerMainMenu/> 
                    </div>}
                    {!isComponentVisible && (
                    <>
                    <p className="ComputerMainMenu_P"> Menu </p>
                    </>
                )}
                </div>
            </section>
        <h1 className="BlogH1"> Stay updated with CTRI's News</h1>
            <section className="BlogSectionOption">
                <div>
                    <div className="BlogOption">
                        <div className="ImageBlog">
                            <NavLink to="/Login">
                                <img src={InternshipSuccess} alt="This is represents the success of our interns and their future career endeavors." width="400px" height="400px"/>
                            </NavLink>
                        </div>
                        <div className="DivBlogDescriptor">
                            <NavLink to="/Login">
                                <p className="PBlog"> Internship Success Stories </p>
                            </NavLink>
                            <p className="BlogDescriptor"> CTRI is excited to share our internship success stories with you. We feature unique and impactful work that our future leaders of tomorrow are performing today. Please join us in celebrating the accomplishments while looking forward to hearing from our next cohort of interns.</p>
                            <NavLink to="/Login">
                                <p className="BlogCTA"> View Success Stories </p>
                            </NavLink>
                        </div>
                    </div>
                    <div className="BlogOption">
                        <div className="ImageBlog">
                            <NavLink to="/Login">
                                <img src={CTRIAwards} alt="This represents the awards that the Community Translational Research Insitute has won over the years." width="400px" height="400px"/>
                            </NavLink>
                        </div>
                        <div className="DivBlogDescriptor">
                            <NavLink to="/Login">
                                <p className="PBlog"> Organizational Awards </p>
                            </NavLink>
                            <p className ="BlogDescriptor">It is with great pride that we are able to announce our recent awards from those whom beleive in our mission. Through tireless innovation and dedication, we were able to compete for highly competitive awards to support our mission. Please celebrate with us and the continued legacy of the Community Translational Research Institute.</p>
                            <NavLink to="/Login">
                                <p className="BlogCTA"> View Company Awards </p>
                            </NavLink>
                        </div>
                    </div>
                    <div className="BlogOption">
                        <div className="ImageBlog">
                            <NavLink to="/Login">
                                <img src={CTRIAwards} alt="This represents the updates from the company that we are trying to share with the larger community." width="400px" height="400px"/>
                            </NavLink>
                        </div>
                        <div className="DivBlogDescriptor">
                            <NavLink to="/Login">
                                <p className="PBlog"> Organizational Updates </p>
                            </NavLink>
                            <p className ="BlogDescriptor">We are excited to share the direction of the company, so you can be up to speed on our latest endeavors. Should this motivate you to work with us, then please do not hesitate to reach out and join our mission.</p>
                            <NavLink to="/Login">
                                <p className="BlogCTA"> View Company Updates </p>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </section>
        <div>
            <h2 className="BlogH2"> Subscribe to our newsletter, so we can share our major news! </h2>
            <p className="BlogH2"> Email us at Ctri.admin@ctris.org</p>
        </div>
        </section>
    )
};

export default Blog;