import React,{ useState } from "react";
import {NavLink} from "react-router-dom";

import PRATSLogo from "../Images/PRATS/PRATSLogo.webp";
import ComputerMainMenu_Icon from "../Images/Icons/ComputerMainMenu_Icon.svg";
import Media10 from "../Images/PRATS/PRATSAboutPhotos/Media10.webp";
import Media11 from "../Images/PRATS/PRATSAboutPhotos/Media11.webp";
import Media12 from "../Images/PRATS/PRATSAboutPhotos/Media12.webp";
import Media13 from "../Images/PRATS/PRATSAboutPhotos/Media13.webp";
import Media14 from "../Images/PRATS/PRATSAboutPhotos/Media14.webp";
import Media15 from "../Images/PRATS/PRATSAboutPhotos/Media15.webp";


import ComputerMainMenu from "../Forms/ComputerMainMenu";


import "../Styles/PRATSHome.css";
import "../Styles/PRATSAbout.css";

function PRATSTeam() {
  const [isComponentVisible, setIsComponentVisible] = useState(false);

  const toggleComponentVisibility = () => {
      setIsComponentVisible(!isComponentVisible);
  };
  return (
  <section>
    <section className="RightScroll">
            <div className= {`RightScrollMenu ${isComponentVisible ? 'expanded' : ''}`} onClick={toggleComponentVisibility}>
                {!isComponentVisible && (
                    <>
                    <p className="ComputerMainMenu_P"> Main </p>
                    <img
                        src={ComputerMainMenu_Icon} 
                        alt="Click this for the menu navigation bar" 
                        width="75px" 
                        height="75px" 
                        className={`ComputerModalButton ${isComponentVisible ? 'rotateAndMoveRight' : ''}`} />
                    </>
                )}
                    {isComponentVisible && 
                    <div>
                    <ComputerMainMenu/> 
                    </div>}
                    {!isComponentVisible && (
                    <>
                    <p className="ComputerMainMenu_P"> Menu </p>
                    </>
                )}
                </div>
    </section>
    <section className="PRATSHomePage">
      <div className="PRATSTopNavigation">
        <div className="PRATSTopLogo">
          <img src={PRATSLogo} alt="This is the PRATS Logo" width="300px" height="200px"/>
        </div>
        <div className="TopNavBarDiv">
        <NavLink to="/PRATSHome">
            <p className="PRATSDT"> Home </p>
        </NavLink>
        <NavLink to="/PRATSAbout">
            <p className="PRATSDT"> ACEs and Toxic Stress </p>
        </NavLink>
        <NavLink to="/PRATSServices">
            <p className="PRATSDT"> Services </p>
        </NavLink>
        <NavLink to="/PRATSTeam">
            <p className="PRATSDT"> Meet the Team! </p>
        </NavLink>
        <NavLink to="/PRATSContact">
            <p className="PRATSDT"> Contact </p>
        </NavLink>
        </div>
      </div>
    </section>
    <section className="PRATSHPHERO">
      <div className="PRATSHHero">
        <h1 className="PRATSH1About"> Meet the Team!</h1>
        <h2 className="PRATSH1About"> Clinicians </h2>
        <div className="CRResources">
          <div className="CTRICRResource">
                        <div className="CRResourceImage">
                            <h2 className="PRATSD2Topic"> Brian Tate</h2>
                            <img src={Media15} alt="." width="400px" height="500px"/>
                        </div>
                        <div className="CRTextBoxPRATS">
                          <p className="PRATSD2"> Hi! I'm Brian Tate, I am a clinician as well as the supervisor for the mentor team. I received my Master's from Loma Linda University in Social Work. I would love to help individuals at a call center that are part of the LGBTQIA+ community.  </p>                     
                          <p className="PRATSD2"> I was given the amazing opportunity to work with CTRI when I was doing my internship. Once I graduated they took me on to be a clinician as well as the supervisor. This has allowed me to grow and become the Social Worker and Supervisor I am today. </p>
                          <p className="PRATSD2"> When it comes to how I am still learning about myself, I am figuring out how to juggle being both the clinician and supervisor. I get enjoyment out of serving those who need assistance and leading a team that is passionate about helping individuals in surrounding areas. But I still struggle with how to take the time for myself, and each position without being stretched too thin. </p>
                        </div>
          </div>
        <h2 className="PRATSH1About"> Mentors </h2>
          <div className="CTRICRResource">
                        <div className="CRResourceImage">
                            <h2 className="PRATSD2Topic"> Seris Castillo</h2>
                            <img src={Media11} alt="." width="400px" height="500px" />
                        </div>
                        <div className="CRTextBoxPRATS">
                          <p className="PRATSD2"> Hello! I am Seris Estrella Castillo, I am a queer Latinx individual who is currently working towards my bachelor's in psychology. In the future, I want to work as a therapist that offers art as a form of therapy when working with the Latinx community. </p>                       
                          <p className="PRATSD2"> I joined PRATS because I wanted to gain experience on how it is to help someone on a one-to-one basis and with something that is not academic-based. I also wanted to be able to work with individuals who were in a similar field academically to understand their journey in academia</p>
                          <p className="PRATSD2"> I am still trying to understand my relationship with academic research as I try to find what I am passionate about and how to go about it. As of right now, I want to try and combine the impact of the lack of cultural representation from those who are from Central America to the formation of their identity and how it impacts future achievement and mental health.  </p>
                        </div>
          </div>
          <div className="CTRICRResource">
                        <div className="CRResourceImage">
                            <h2 className="PRATSD2Topic"> Skyler Howard</h2>
                            <img src={Media10} alt="." width="400px" height="500px"/>
                        </div>
                        <div className="CRTextBoxPRATS">
                          <p className="PRATSD2"> Hi! I am Skyler Howard, a Black and Mexican-American first-generation student from California! I am a recent graduate from University of La Verne, receiving my Bachelor's in Psychology in Spring of 2023. I am currently enjoying working in the field of Psychology and Public Health in any way I can, however in the future I would love to become a Clinical Counselor!</p>                     
                          <p className="PRATSD2"> I joined PRATS as a mentor during my junior year of college looking to gain experience hands-on experience in my field, while also serving my community! I have been able to work with both adolescents and adults, while also gaining experience working as a team, working under a supervisor, and networking! I am extremely grateful for my role at CTRI and am excited to see how far this project will go!</p>
                          <p className="PRATSD2"> I still have so much to learn about my field and myself. Currently, I am still learning to push myself past my comfort zone both personally and professionally, because I believe that growth begins where comfort ends. I am also still learning what I want to do with my degree, and where to let it take me. I am in a very transitional phase of life right now, and while I am anxious for what is to come, I can't wait for the ride!</p>
                        </div>
          </div>
          <div className="CTRICRResource">
            <div className="CRResourceImage">
                            <h2 className="PRATSD2Topic"> Cesar Ramirez</h2>
                            <img src={Media13} alt="." width="400px" height="500px"/>
                        </div>
                        <div className="CRTextBoxPRATS">
                          <p className="PRATSD2"> My name is Cesar Ramirez, I am currently majoring in Psychology at Mt. San Antonio College. I love being supportive and helping others whenever I get the opportunity.</p>
                          <p className="PRATSD2"> I joined this program because it focuses on providing resources to those who need it. Many communities still have a stigma towards mental health and this program bridges that gap by providing mentors who are capable in their abilities to provide services and support necessary to start healing these communities. </p>                     
                          <p className="PRATSD2"> I am still learning how to work on self-care and my own mental health/ well-being. As someone in the health field, it is also important to take care of myself as much as possible. I need to be ready in order to help others.</p>
                        </div>
          </div>
          <div className="CTRICRResource">
                        <div className="CRResourceImage">
                            <h2 className="PRATSD2Topic"> Lisandra Solis</h2>
                            <img src={Media14} alt="." width="400px" height="500px"/>
                        </div>
                        <div className="CRTextBoxPRATS">
                          <p className="PRATSD2"> Hello! My name is Lisandra Solis, and I'm a first-generation college student majoring in Psychology. </p>                     
                          <p className="PRATSD2"> I'm currently a mentor at PRATS because I would like to help my community and those in my community. I am a Pomona resident and I think that if we work together we can uplift one another. The San Gabriel Valley serves people of many backgrounds but I'd specifically like to help diminish the stigma that many people of Hispanic origin hold against mental health and methods of wellness such as therapy.</p>
                          <p className="PRATSD2"> I still have a lot to learn about myself and my career. I'm a community college student, and I'm still not quite sure what part of the field I'd like to focus on once I graduate, I still have a lot to learn about my field. Despite my unclear future, I believe that I will figure out my path as I continue moving forward academically. </p>
                        </div>
          </div>
          <div className="CTRICRResource">
                        <div className="CRResourceImage">
                            <h2 className="PRATSD2Topic"> Vanessa Crus-Fundora</h2>
                            <img src={Media12} alt="." width="400px" height="500px"/>
                        </div>
                        <div className="CRTextBoxPRATS">
                          <p className="PRATSD2"> Hi there! My name is Vanessa Cruz-Fundora. I'm a student at Cal Poly Pomona, majoring in Psychology. I am passionate about being there for others who are going through tough times. Having faced my own share of challenges in life, I understand the importance of a supportive hand during difficult moments. </p>                     
                          <p className="PRATSD2"> I'm here to lend an empathetic ear and offer a helping hand whenever needed. I'm on a mission to gain hands-on experience in providing support to others. Being part of PRATS helps prepare me for my future in clinical work, gaining valuable experience while beefing up my resume, one step at a time.</p>
                          <p className="PRATSD2"> Recognizing that people are more than their minds, and embracing the beauty of their unique struggles is very important to me. Let's navigate life's ups and downs together!</p>
                        </div>
          </div>
        </div>
      </div>
    </section>
  </section>
  );
}

export default PRATSTeam;