import React,{ useState } from "react";
import {NavLink} from "react-router-dom";

import PRATSMap from "../Images/PRATS/PRATSHomepageMap.webp";
import PRATSLogo from "../Images/PRATS/PRATSLogo.webp";
import ComputerMainMenu_Icon from "../Images/Icons/ComputerMainMenu_Icon.svg";


import ComputerMainMenu from "../Forms/ComputerMainMenu";


import "../Styles/PRATSHome.css";

function PRATSHome() {
  const [isComponentVisible, setIsComponentVisible] = useState(false);

  const toggleComponentVisibility = () => {
      setIsComponentVisible(!isComponentVisible);
  };
  return (
  <section>
    <section className="RightScroll">
            <div className= {`RightScrollMenu ${isComponentVisible ? 'expanded' : ''}`} onClick={toggleComponentVisibility}>
                {!isComponentVisible && (
                    <>
                    <p className="ComputerMainMenu_P"> Main </p>
                    <img
                        src={ComputerMainMenu_Icon} 
                        alt="Click this for the menu navigation bar" 
                        width="75px" 
                        height="75px" 
                        className={`ComputerModalButton ${isComponentVisible ? 'rotateAndMoveRight' : ''}`} />
                    </>
                )}
                    {isComponentVisible && 
                    <div>
                    <ComputerMainMenu/> 
                    </div>}
                    {!isComponentVisible && (
                    <>
                    <p className="ComputerMainMenu_P"> Menu </p>
                    </>
                )}
                </div>
      </section>
    <section className="PRATSHomePage">
      <div className="PRATSTopNavigation">
        <div className="PRATSTopLogo">
          <img src={PRATSLogo} alt="This is the PRATS Logo" width="300px" height="200px"/>
        </div>
        <div className="TopNavBarDiv">
          <NavLink to="/PRATSHome">
            <p className="PRATSDT"> Home </p>
          </NavLink>
          <NavLink to="/PRATSAbout">
            <p className="PRATSDT"> ACEs and Toxic Stress </p>
          </NavLink>
          <NavLink to="/PRATSServices">
            <p className="PRATSDT"> Services </p>
          </NavLink>
          <NavLink to="/PRATSTeam">
            <p className="PRATSDT"> Meet the Team! </p>
          </NavLink>
          <NavLink to="/PRATSContact">
            <p className="PRATSDT"> Contact </p>
        </NavLink>
        </div>
      </div>
    </section>
    <section className="PRATSHPHERO">
      <div className="PRATSHHero">
        <h1 className="PRATSH1"> Our approach reflects the people we serve. <br></br> We are diverse, but we strive to build connections.</h1>
      </div>
      <div className="PRATSHHEROTwo">
        <div className="ShareSpace">
          <img src={PRATSMap} alt="This is the service area for the PRATS services." width="800px" height="800px"/>
        </div>
        <div className="ShareSpace">
          <p className="PRATSD"> The PRATS, Pevention and Remediation of ACEs and Toxic Stress, program mission is to address medical and social-emotional-neurocognitive sequelae of ACEs and chronic toxic stress experienced from early childhood through adulthood in 15+ cities in the East San Gabriel Valley. These issues are addressed with Cognitive behavioral therapy-based counseling along with additional support programs like mentors and referrals to counseling services. </p>
          <p className="PRATSD"> We hope that by addressing these issues we can not only help an individual learn more about themselves, better their mental health, and create a long-term goal for them to strive but to better the East San Gabriel Vally as a whole. We strive to fill the gaps in public health by offering free counseling and mentoring services to the public.</p>
        </div>
      </div>
      <div className="NavBarDiv"> 
        <NavLink to="/PRATSAbout">
          <p className="PRATSNAV"> What is ACEs and Toxic Stress? </p>
        </NavLink>
        <NavLink to="/PRATSServices">
          <p className="PRATSNAV"> Learn More About What We Offer! </p>
        </NavLink>
        <NavLink to="/PRATSTeam">
          <p className="PRATSNAV"> Meet the Team! </p>
        </NavLink>
        <NavLink to="/PRATSContact">
          <p className="PRATSNAV"> Get in Contact With Us </p>
        </NavLink>
      </div>
    </section>
  </section>
  );
}

export default PRATSHome;
