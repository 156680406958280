import React,{ useState } from "react";
import {NavLink} from "react-router-dom";
import "../Styles/CommunityResources.css";

import CTRILogo from "../Images/CTRI_Logo.png";
import Menu_Icon from "../Images/Icons/Menu_Icon.svg";
import ProfessionalCounseling from "../Images/ProfessionalCounseling.webp";
import PeerMentorship from "../Images/Peer_Mentorship.webp";
import FitnessCoach from"../Images/Health_Coaching.webp";
import NutritionCoach from "../Images/Nutrition_Coaching.webp";
import DPProgram from "../Images/DP_Program.webp";
import DietNutrition from "../Images/DietandNutrition.webp";
import Social from "../Images/Social_Connectedness.webp";
import SocialWellBeing from "../Images/Social_WellBeing_Photo.webp";
import ChronicDisease from "../Images/ChronicDiseases_Photo.webp";
import ComputerMainMenu_Icon from "../Images/Icons/ComputerMainMenu_Icon.svg";

import EnrollmentForm from "../Forms/enrollmentForm";
import ComputerMainMenu from "../Forms/ComputerMainMenu";


function CommunityResources () {
    function handleSmoothScroll (e,id) {
        e.preventDefault();
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({behavior:"smooth"});
        }
    }
    const [isComponentVisible, setIsComponentVisible] = useState(false);

    const toggleComponentVisibility = () => {
        setIsComponentVisible(!isComponentVisible);
    };

    return (
        <section id="Top">
            <section className="MobileLogo">
                <div className="LogoDown">
                    <NavLink to="/">
                        <img src={CTRILogo} alt="Community Translational Research Institute's main logo" width="300px" height="100px" />
                    </NavLink>
                </div>
                <div className="MobileNavBar" onClick={toggleComponentVisibility}>
                    <img
                        src={Menu_Icon} 
                        alt="Click this for the menu navigation bar" 
                        width="75px" 
                        height="75px" 
                        className={`ModalButton ${isComponentVisible ? 'rotate' : ''}`} />
                    <p className="NavBar_P"> Menu </p>
                </div>
                {isComponentVisible && 
                <div> <EnrollmentForm/> </div>}
            </section>
            <section className="RightScroll">
            <div className= {`RightScrollMenu ${isComponentVisible ? 'expanded' : ''}`} onClick={toggleComponentVisibility}>
                {!isComponentVisible && (
                    <>
                    <p className="ComputerMainMenu_P"> Main </p>
                    <img
                        src={ComputerMainMenu_Icon} 
                        alt="Click this for the menu navigation bar" 
                        width="75px" 
                        height="75px" 
                        className={`ComputerModalButton ${isComponentVisible ? 'rotateAndMoveRight' : ''}`} />
                    </>
                )}
                    {isComponentVisible && 
                    <div>
                    <ComputerMainMenu/> 
                    </div>}
                    {!isComponentVisible && (
                    <>
                    <p className="ComputerMainMenu_P"> Menu </p>
                    </>
                )}
                </div>
            </section>
            <section className="CRHeroSection">
                    <div>
                        <h1 className="CRH1"> Click What Fits</h1>
                    </div>
                <div className="CRSubNavigation">
                    <p className="CRSubNavText"><NavLink to="#SupportiveServices" onClick={(e) => handleSmoothScroll (e, "SupportiveServices")}>Supportive Services</NavLink></p>
                    <p className="CRSubNavText"><NavLink to="#DigitalHealthcare"  onClick={(e) => handleSmoothScroll (e, "DigitalHealthcare")}> Digital Healthcare</NavLink></p>
                    <p className="CRSubNavText"><NavLink to="#OlderAdults"  onClick={(e) => handleSmoothScroll (e, "OlderAdults")}>Older Adults</NavLink></p>
                    <p className="CRSubNavText"><NavLink to="#HealthAssessments"  onClick={(e) => handleSmoothScroll (e, "HealthAssessments")}> Health Assessments</NavLink></p>
                </div>
                <div>
                </div>
            </section>
            <section className="CRResources">
                <div>
                    <h2 className="CRH2" id="SupportiveServices">Supportive Services</h2>
                    <div className="CTRICRResource">
                        <div className="CRResourceImage">
                            <img src={ProfessionalCounseling} alt="This is our resource to help you obtain a fresh perspective towards life. it features two people talking together in a safe space." width="400px" height="400px"/>
                        </div>
                        <div className="CRTextBox">
                          <h3 className="CRH3"> Professional Counseling </h3>
                          <p className="CRPDescriptor"> We offer a safe space to talk and think of a fresh perspective. With our counselors, you can explore feelings, thoughts, and solutions. We are trained to help and support you through life's challenges.</p> 
                          <NavLink to="https://cgu.co1.qualtrics.com/jfe/form/SV_3qNqAX3mdevcOLs" target="_blank">
                                <p className="CRCTA"> Click To Enroll </p>
                            </NavLink>                        
                        </div>
                    </div>
                    <div className="CTRICRResource">
                        <div className="CRResourceImage">
                            <img src={PeerMentorship} alt="People enjoying a wonderful time together, and forging a community of strong bonds." width="400px" height="400px" />
                        </div>
                        <div className="CRTextBox">
                          <h3 className="CRH3"> Peer Mentorship </h3>
                          <p className="CRPDescriptor"> We try our best to connect you with someone who has been in your shoes. Together, we share experiences and grow from them in a private setting. This is a unique opportunity to talk with someone who understands you.</p> 
                          <NavLink to="https://cgu.co1.qualtrics.com/jfe/form/SV_3qNqAX3mdevcOLs" target="_blank">
                                <p className="CRCTA"> Click To Enroll </p>
                            </NavLink>                        
                        </div>
                    </div>
                    <div className="CTRICRResource">
                        <div>
                            <img src={NutritionCoach} alt="An individual centered approach to coaching holistically." width="400px" height="400px" />
                        </div>
                        <div className="CRTextBox">
                          <h3 className="CRH3"> Health Coaching </h3>
                          <p className="CRPDescriptor"> Our health and wellness coaches focus on your perosnalized journey. It is our goal for you to achieve your health goals, whether that is fitness, nutrition or more. It's your path, but we hope to walk with you.</p> 
                          <NavLink to="https://cgu.co1.qualtrics.com/jfe/form/SV_3qNqAX3mdevcOLs" target="_blank">
                                <p className="CRCTA"> Click To Enroll </p>
                            </NavLink>
                        </div>
                    </div>
                    <div className="CTRICRResource">
                        <div>
                            <img src={FitnessCoach} alt="An individual centered approach to coaching holistically." width="400px" height="400px" />
                        </div>
                        <div className="CRTextBox">
                          <h3 className="CRH3"> Physical Fitness Coach </h3>
                          <p className="CRPDescriptor"> We break down barriers that have limited your ability to succeed through planning sessions. We help identify areas in your lifestyle to naturally burn more calories. Through 1 on 1 training sessions, we provide you tools and knowledge to build lasting physical fitness.</p> 
                          <NavLink to="https://cgu.co1.qualtrics.com/jfe/form/SV_3qNqAX3mdevcOLs" target="_blank">
                                <p className="CRCTA"> Click To Enroll </p>
                            </NavLink>                        
                        </div>
                    </div>
                </div>
                <div>
                    <h2 className="CRH2" id="DigitalHealthcare"> Digital Healthcare </h2>
                    <div className="CTRICRResource">
                        <div>
                            <img src={DPProgram} alt="An individual centered approach to coaching holistically." width="400px" height="400px" />
                        </div>
                        <div className="CRTextBox">
                          <h3 className="CRH3"> Diabetes Prevention Program </h3>
                          <p className="CRPDescriptor"> We empower those prone to diabetes in order to avoid obtaining the disease. We center you at the heart of our care team to focus on your unique needs. Our goal is to connect you with resources that will positively impact your life.</p> 
                          <NavLink to="https://cgu.co1.qualtrics.com/jfe/form/SV_3qNqAX3mdevcOLs" target="_blank">
                                <p className="CRCTA"> Click To Enroll </p>
                            </NavLink>                        
                        </div>
                    </div>
                    <div className="CTRICRResource">
                        <div>
                            <img src={DietNutrition} alt="This is our resource to help you obtain a fresh perspective towards life. it features two people talking together in a safe space." width="400px" height="400px"/>
                        </div>
                        <div className="CRTextBox">
                          <h3 className="CRH3"> Diet and Nutrition </h3>
                          <p className="CRPDescriptor"> Diet and nutrition services craft a healthier you through food. Discover meals and habits that fuel your body right. With expert advice, embrace a lifestyle that nourishes both body and mind.</p> 
                          <NavLink to="https://cgu.co1.qualtrics.com/jfe/form/SV_3qNqAX3mdevcOLs" target="_blank">
                                <p className="CRCTA"> Click To Enroll </p>
                            </NavLink>                        
                        </div>
                    </div>
                </div>
                <div> 
                    <h2 className="CRH2" id="OlderAdults"> Older Adults </h2>
                    <div className="CTRICRResource">
                        <div>
                            <img src={Social} alt="This is our resource to help you obtain a fresh perspective towards life. it features two people talking together in a safe space." width="400px" height="400px"/>
                        </div>
                        <div className="CRTextBox">
                          <h3 className="CRH3"> Social Connection </h3>
                          <p className="CRPDescriptor"> Our program brings older adults together, creating bonds and friendships. Dive into activities, chats, and events that spark joy and connection. Combat loneliness and embrace a community that understands and values every golden year.</p> 
                          <NavLink to="https://cgu.co1.qualtrics.com/jfe/form/SV_3qNqAX3mdevcOLs" target="_blank">
                                <p className="CRCTA"> Click To Enroll </p>
                            </NavLink>                        
                        </div>
                    </div>
                </div>
                <div> 
                    <h2 className="CRH2" id="HealthAssessments"> Health Assessments</h2>
                    <div className="CTRICRResource">
                        <div>
                        <img src={SocialWellBeing} alt="An individual centered approach to coaching holistically." width="400px" height="400px" />
                        </div>
                        <div className="CRTextBox">
                          <h3 className="CRH3"> Social Well-Being  </h3>
                          <p className="CRPDescriptor"> Our health assessment zeroes in on your social well-being. Dive deep into how relationships and interactions influence your health. With personalized insights, understand and nurture the social aspects of your well-being journey.</p> 
                          <NavLink to="https://cgu.co1.qualtrics.com/jfe/form/SV_3qNqAX3mdevcOLs" target="_blank">
                                <p className="CRCTA"> Click To Enroll </p>
                            </NavLink>                        
                        </div>
                    </div>
                    <div className="CTRICRResource">
                        <div>
                        <img src={ChronicDisease} alt="An individual centered approach to coaching holistically." width="400px" height="400px" />
                        </div>
                        <div className="CRTextBox">
                          <h3 className="CRH3"> Chronic Diseases </h3>
                          <p className="CRPDescriptor"> Our health assessment targets chronic diseases like diabetes and heart conditions. Gain deep insights into risk factors and understand your health landscape. With tailored feedback, take informed steps towards prevention and management.<br></br></p>
                          <NavLink to="https://cgu.co1.qualtrics.com/jfe/form/SV_3qNqAX3mdevcOLs" target="_blank">
                                <p className="CRCTA"> Click To Enroll </p>
                            </NavLink>                        
                        </div>
                    </div>
                </div>
                <div>
                    <p className="MobileScrollUp"><NavLink to="Top" onClick={(e) => handleSmoothScroll (e,"Top")}>Click to Scroll Up </NavLink></p>
                </div>
            </section>
            <section className="TopScroll">
                <div>
                    <p className="CRPDescriptor"><NavLink to="Top" onClick={(e) => handleSmoothScroll (e,"Top")}>Click <br></br> To <br></br> Scroll<br></br> up </NavLink></p>
                </div>
            </section>
        </section>
    )
};

export default CommunityResources;