import React from "react";
import {NavLink} from "react-router-dom";

import PRATSLogo from "../Images/PRATS/PRATSLogo.webp";

import "../Styles/ComputerMainMenu.css";

function ComputerMainMenu() {
  return (
    <section className="CMM_TOP">
      <div>
        <p className="DesktopMenuH1"> Welcome, This is a list for current and future programs that we have created for you.</p>
        <p className="DesktopMenuH2"> As of 2024, we are focused on expanding our next program. Stay Tuned!</p>
      </div>
      <div>
        <div className="PRATSMenu">
          <NavLink to="/PRATSHome">
            <img src={PRATSLogo} alt="Prevention and Remediation of Adverse Childhood Experiences and Toxic Stress Main Logo" width="500px" height="300px" />
          </NavLink>
          <div className="PRATSDescription">
            <p className="TextMenu"> Enjoy 8 to 12 free sessions with Master Social Workers online or in-person to tackle anxiety and depression, no insurance needed. </p>
            <NavLink to="/PRATSHome">
              <p className="MainCTAPRATS"> Click For Free Access </p>
            </NavLink>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ComputerMainMenu;