import React from "react";
import {NavLink} from "react-router-dom";
import "../Styles/Navbar.css";
import CTRILogo from "../Images/CTRI_Logo.png";
import UserIcon from "../Images/User-Account-Icon.png";

function NavBar() {
    return (
    <div className="NavigationBar">
        <div className="logo">
            <NavLink to="/">
            <img src={CTRILogo} alt="Community Translational Research Institute's main logo" width="300px" height="100px" />
            </NavLink>
        </div>
        <div className="links">
            <NavLink to="/" activeClassName="active"> Home</NavLink>
            <NavLink to="/About" activeClassName="active"> About</NavLink>
            <NavLink to="/CommunityResources" activeClassName="active"> Community Services</NavLink>
            <NavLink to="/LogIn" activeClassName="active">
            <img src={UserIcon} alt="Access your user account by clicking here."/>
            </NavLink>
        </div>
    </div>
    )
};

export default NavBar;